import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { Button } from '@trexity/common-client/lib/components'
import MerchantSignUpButton from '../components/merchantSignUpButton'

export default function NoLastCall () {
  return (
    <Layout>
      <div style={{ maxWidth: 880, margin: '0 auto' }}>
        <section className='mb-0'>
          <div className='inner'>
            <h1>No “last call” for Trexity deliveries this Holiday Season</h1>
            <p className='lede'>Did you know that the Canada Post cut off date for local delivery is Monday December 20th?</p>
            <p className='lede'>Did you also know that Trexity has no cut off dates and we will be delivering right up to December 25th?</p>
          </div>
        </section>

        <section className='mb-0'>
          <div className="inner">
            <h2>Trexity features</h2>
            <ol>
              <li className='row preserve' style={{ marginBottom: 15 }}>
                <i className='material-icons green' style={{ marginRight: 10 }}>check_circle</i>
                Live tracking of packages to the minute
              </li>
              <li className='row preserve' style={{ marginBottom: 15 }}>
                <i className='material-icons green' style={{ marginRight: 10 }}>check_circle</i>
                Delivery bundle options that help keep costs down, and less cars off the road
              </li>
              <li className='row preserve' style={{ marginBottom: 15 }}>
                <i className='material-icons green' style={{ marginRight: 10 }}>check_circle</i>
                E-commerce integration capabilities with your website
              </li>
              <li className='row preserve' style={{ marginBottom: 15 }}>
                <i className='material-icons green' style={{ marginRight: 10 }}>check_circle</i>
                No subscriptions or commitments, and we don’t take a commission from your sale
              </li>
              <li className='row preserve'>
                <i className='material-icons green' style={{ marginRight: 10 }}>check_circle</i>
                No holiday delivery delays or booking deadlines
              </li>
            </ol>
          </div>
        </section>

        <section className='mb-0'>
          <div className="inner">
            <h2>Your first delivery is on us</h2>
            <p className='lede'>Between now and December 25th, Trexity is covering first deliveries for all new merchants (up to $25 dollars). </p>
            <p className='mb'>Don’t let those last minute shoppers down, sign up with Trexity today and you could be offering delivery to your customers within twenty minutes. We’d love to be your delivery partner as you head into this busy holiday season.</p>
            <MerchantSignUpButton />
          </div>
        </section>

        <section>
          <div className="inner mice">
            Source: <a href="https://www.canadapost-postescanada.ca/cpc/en/our-company/news-and-media/corporate-news/holiday-sending-guidelines.page" target="_blank" rel="noreferrer">Canada Post guidelines for sending your holiday cards and packages</a>
          </div>
        </section>
      </div>
    </Layout>
  )
}
